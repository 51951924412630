const url = 'https://klz-stories.rvspace.ru/n';

async function upload(data) {
    let response = await fetch( url + '/upload', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(res => res.json(), (err) => {
        console.log(err);
    });
    if (response && response.result == true) {
        return true;
    }
    return false;
}

export { upload };