import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { upload } from "../Disp/endpoint";

function MainForm(props) {
    const [className, setClassName] = useState("window");
    const [phone, setPhone] = useState("");
    const [lock, setLock] = useState(false);
    const [files, setFiles] = useState([]);
    const [upError, setUpError] = useState("");
    const [upSuccess, setSuccess] = useState("");

    function readFile(file) {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                res(reader.result);
            }, false);
            reader.readAsDataURL(file);
        });
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        let _files = [];
        for (let _file of acceptedFiles) {
            _files.push({
                data_url: await readFile(_file),
                name: _file.name,
                type: _file.type || 'application/octet-stream',
                size: _file.size
            });
        }
        setFiles(_files);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const dropImages = () => {
        setFiles([]);
    }

    const send = () => {
        if (files.length == 0) {
            setUpError("Добавьте фотки то!");
            return;
        }

        if (!phone) {
            setUpError("Укажите свой телефон.");
            return;
        }

        if (!lock) {
            setLock(true);
            upload({
                images: files,
                phone: phone
            }).then((value) => {
                setLock(false);
                setUpError('');
                if (value == true) {
                    setPhone("");
                    setFiles([]);
                    setSuccess('Успешно загружено.');
                } else {
                    setUpError('Ошибка сервера.');
                }
            });
        }
    }

    return (
        <div className="main">
            <div key={"window"} className={className}>
                <h2 style={{ "text-align": "center" }}>Stories</h2>
                <p style={{ "text-align": "center" }} className="error">{upError}</p>
                <p style={{ "text-align": "center" }} className="success">{upSuccess}</p>
                {lock && <div style={{ "text-align": "center" }}><div class="lds-hourglass"></div></div> }
                <div className="project" >
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {[files.length == 0 && <div className="loadbox">
                            <div className="load">
                                <button ></button>
                            </div>
                        </div>,
                        files.length > 0 && [<div className="images-box">
                            {
                                files.map((file, index, arr) => {
                                    if (file?.type && file.type.indexOf('image') != -1) {
                                        return <img key={index} src={file.data_url}></img>
                                    } else {
                                        return <div className="no-type">
                                            <img key={index} src="https://create.infty.art/images/png/item.png"></img>
                                            <p>{file.name}</p>
                                        </div>
                                    }
                                })}
                        </div>
                        ]]}
                    </div>
                    {files.length > 0 && <div className="buttons-box">
                        <button onClick={dropImages}>Reset</button>
                    </div>}
                    <div className="input">
                        <label>Телефон</label>
                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className="buttons-group">
                        <button className="sub-button" disabled={lock} onClick={(e) => send()}>Отправить</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainForm;